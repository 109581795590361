import { LayoutProps, Menu, MenuProps, useSidebarState } from "react-admin";
import { MergeType } from "@mui/icons-material";
import { Layout } from "react-admin";
import { AuAppBar } from "./AuAppBar";
import { Box, Button, Typography } from "@mui/material";
import { OrgPicker } from "../superuser/org-picker";
import { SuperUserOnly } from "../superuser/superuser-only";
import { auTheme } from "../../admin/theme";
import { UserPicker } from "../superuser/user-picker";
import { useContext, useState } from "react";
import { UserContext } from "../../logic/auth/user-context";
import { AuScope, hasScope } from "@arrowsup/platform-dtos";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

export const AuLayout = (props: JSX.IntrinsicAttributes & LayoutProps) => (
  <Layout {...props} menu={AuMenu} appBar={AuAppBar} />
);

/**
 * The menu for the entire ReactAdmin frontend.
 * Was necessary to be able to hide/show different menu items, while keeping
 * the actual components themselves.
 */
export const AuMenu: React.FC<MenuProps> = () => {
  const userContext = useContext(UserContext);
  const permissions =
    userContext?.impersonatedUser?.policies ?? userContext.user.policies;
  const canViewIntegrations = hasScope(permissions, AuScope.IntegrationsUI);
  const canViewGoodDataOrgConfig = hasScope(
    permissions,
    AuScope.GoodDataOrgConfigRead
  );
  const canViewDashboards = hasScope(
    permissions,
    AuScope.GoodDataDashboardRead
  );
  const [sidebarOpen] = useSidebarState();
  const canViewConversions = hasScope(permissions, AuScope.ConversionRead);
  const canViewConversionFiltersList = hasScope(
    permissions,
    AuScope.ConversionFilterListRead
  );
  const canViewConversionOutputStream = hasScope(
    permissions,
    AuScope.ConversionOutputStreamRead
  );
  const [showSettings, setShowSettings] = useState(false);
  const canViewLandingPages = hasScope(permissions, AuScope.LandingPagesRead);
  const canViewWebViewFilters = hasScope(
    permissions,
    AuScope.WebViewFilterRead
  );
  const canViewTracking = hasScope(permissions, AuScope.TrackingRead);
  const canViewFunnels = hasScope(permissions, AuScope.FunnelRead);

  const menuItems: JSX.Element[] = [];

  if (canViewDashboards) {
    menuItems.push(<Menu.ResourceItem key="gd-dash-config" name="dashboard" />);
  }

  if (canViewConversions) {
    menuItems.push(<Menu.ResourceItem key="conversion" name="conversion" />);
  }

  menuItems.push(
    <Button
      key="settings-toggle"
      color="inherit"
      onClick={() => setShowSettings((last) => !last)}
    >
      {sidebarOpen && "Settings"}
      {showSettings ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
    </Button>
  );

  if (showSettings) {
    menuItems.push(<Menu.ResourceItem key="users" name="users" />);

    if (canViewConversionFiltersList) {
      menuItems.push(
        <Menu.ResourceItem key="conversion-filter" name="conversion-filter" />
      );
    }

    if (canViewConversionOutputStream) {
      menuItems.push(
        <Menu.ResourceItem
          key="conversion-output-stream"
          name="conversion-output-stream"
        />
      );
    }

    if (canViewWebViewFilters) {
      menuItems.push(
        <Menu.ResourceItem key="web-view-filter" name="web-view-filter" />
      );
    }

    if (canViewLandingPages) {
      menuItems.push(
        <Menu.ResourceItem key="landing-page" name="landing-page" />
      );
    }

    if (canViewTracking) {
      menuItems.push(
        <Menu.ResourceItem key="paid-source" name="paid-source" />
      );
      menuItems.push(
        <Menu.ResourceItem key="tracked-call" name="tracked-call" />
      );
      menuItems.push(
        <Menu.ResourceItem key="tracked-url" name="tracked-url" />
      );
    }

    if (canViewFunnels) {
      menuItems.push(
        <Menu.ResourceItem key="funnel-stage" name="funnel-stage" />
      );
      menuItems.push(
        <Menu.ResourceItem
          key="funnel-stage-assignment"
          name="funnel-stage-assignment"
        />
      );
    }

    if (canViewIntegrations) {
      menuItems.push(
        <Menu.Item
          key="integrations"
          to="/integrations"
          primaryText="Integrations"
          leftIcon={<MergeType />}
        />
      );
    }

    if (canViewGoodDataOrgConfig) {
      menuItems.push(
        <Menu.ResourceItem
          key="gooddata-org-config"
          name="gooddata-org-config"
        />
      );
    }
  }

  if (sidebarOpen) {
    menuItems.push(
      <SuperUserOnly key="superuser-only">
        <Box
          ml={2}
          mt={2}
          mb={2}
          mr={1}
          border="solid"
          borderColor={auTheme.palette.secondary.dark}
          borderRadius="4px"
          overflow={"hidden"}
        >
          <Typography
            p={1}
            variant="subtitle2"
            fontSize="0.75rem"
            align="center"
            overflow="hidden"
          >
            Super&nbsp;User
          </Typography>
          <Box p={1}>
            <OrgPicker />
          </Box>
          <Box p={1}>
            <UserPicker />
          </Box>
        </Box>
      </SuperUserOnly>
    );
  }
  return <Menu>{menuItems}</Menu>;
};
