import {
  GoodDataDashboardConfigResource,
  GoodDataOrgConfigResource,
} from "@arrowsup/platform-dtos";
import { Box, Typography } from "@mui/material";
import { ShowBase, useShowContext } from "react-admin";
import { Loading } from "../feedback/loading";
import { ListButton } from "react-admin";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { ErrorBoundary } from "react-error-boundary";
import { selfProvisionGoodDataUser } from "../../logic/gooddata/self-provision-good-data-user";
import { suspend } from "suspend-react";
import { useContext } from "react";
import { ApiContext } from "../../logic/api/api-context";
import tigerFactory, {
  TigerJwtAuthProvider,
} from "@gooddata/sdk-backend-tiger";
import { Dashboard } from "@gooddata/sdk-ui-dashboard";
import { idRef } from "@gooddata/sdk-model";
import { withCaching } from "@gooddata/sdk-backend-base";
import { BackendProvider, WorkspaceProvider } from "@gooddata/sdk-ui";
import "@gooddata/sdk-ui-dashboard/styles/css/main.css";
import "@gooddata/sdk-ui-filters/styles/css/main.css";
import "@gooddata/sdk-ui-charts/styles/css/main.css";
import "@gooddata/sdk-ui-geo/styles/css/main.css";
import "@gooddata/sdk-ui-pivot/styles/css/main.css";
import "@gooddata/sdk-ui-kit/styles/css/main.css";
import "@gooddata/sdk-ui-ext/styles/css/main.css";
import { createGoodDataJwt } from "../../logic/gooddata/create-good-data-jwt";
import { FatalError } from "../feedback/fatal-error";
import { AxiosInstance } from "axios";

const DashboardEmbedWithAccess: React.FC<{
  jwt: string;
  api: AxiosInstance;
}> = ({ jwt, api }) => {
  const showContext = useShowContext();

  if (!showContext.record) {
    return <Loading />;
  }

  const record = showContext.record as GoodDataDashboardConfigResource;
  const dashboardId = record.dashboardId;

  // Hacky: we don't specify it in the resource type, but our typeorm entity eagerly
  // loads the org config, so it's always there.
  // eslint-disable-next-line
  const orgConfig = (record as unknown as any)
    .orgConfig as GoodDataOrgConfigResource;

  // Setup GoodData.
  const jwtAuthProvider = new TigerJwtAuthProvider(
    jwt, // initial JWT
    undefined,
    (setJwt) => {
      // Reset JWT.
      void createGoodDataJwt(api)().then(setJwt);
    }
  );

  const backend = withCaching(
    tigerFactory()
      .onHostname("https://dashboards.goarrowsup.com")
      .withAuthentication(jwtAuthProvider),
    {
      maxExecutions: 10,
      maxResultWindows: 5,
      maxCatalogs: 1,
      maxCatalogOptions: 50,
      maxSecuritySettingsOrgs: 3,
      maxSecuritySettingsOrgUrls: 100,
      maxSecuritySettingsOrgUrlsAge: 300000,
      maxAttributeWorkspaces: 1,
      maxAttributeDisplayFormsPerWorkspace: 100,
      maxAttributesPerWorkspace: 100,
      maxAttributeElementResultsPerWorkspace: 100,
      maxWorkspaceSettings: 1,
    }
  );

  const workspaceId = orgConfig.workspaceId;

  return (
    <Box
      display="flex"
      flexDirection={"column"}
      justifyContent="center"
      alignItems="center"
      paddingTop="1rem"
      width="100%"
      sx={{
        ".component-root": {
          width: "100%", // For some reason the dashboard won't be full width without this.
        },
        ".dash-header.s-top-bar": {
          display: "none", // Redundant.
        },
      }}
    >
      <Box
        display="flex"
        alignContent="space-between"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Typography variant="h6" pl={"1rem"}>
          {record.title}
        </Typography>
        <ListButton label="All Dashboards" icon={<ChevronLeftIcon />} />
      </Box>
      <BackendProvider backend={backend}>
        <WorkspaceProvider workspace={workspaceId}>
          <Dashboard
            dashboard={idRef(dashboardId)}
            eventHandlers={[
              // Add an event handler so URL drill events open in a new tab.
              {
                eval: (event) => {
                  return (
                    event.type ===
                    "GDC.DASH/EVT.DRILL.DRILL_TO_ATTRIBUTE_URL.RESOLVED"
                  );
                },
                handler: (event) => {
                  // eslint-disable-next-line
                  window.open(event.payload.url, "_blank")?.focus();
                },
              },
            ]}
          />
        </WorkspaceProvider>
      </BackendProvider>
    </Box>
  );
};

const DashboardEmbdedWithProvisionedUser: React.FC = () => {
  const api = useContext(ApiContext).api;
  const jwt = suspend(createGoodDataJwt(api), [api, "gd-jwt"]);

  return (
    <ErrorBoundary fallback={<FatalError />}>
      <DashboardEmbedWithAccess jwt={jwt} api={api} />
    </ErrorBoundary>
  );
};

const DashboardEmbed: React.FC = () => {
  const api = useContext(ApiContext).api;
  suspend(selfProvisionGoodDataUser(api), [api, "gd-provision"]);
  return (
    <ErrorBoundary fallback={<FatalError />}>
      <DashboardEmbdedWithProvisionedUser />
    </ErrorBoundary>
  );
};

export const GoodDataDashboardShow = () => {
  return (
    <ShowBase resource="dashboard">
      <ErrorBoundary fallback={<FatalError />}>
        <DashboardEmbed />
      </ErrorBoundary>
    </ShowBase>
  );
};
