import {
  ArrayInput,
  BulkDeleteButton,
  Create,
  Datagrid,
  DateField,
  Edit,
  FormDataConsumer,
  List,
  NumberInput,
  Resource,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextField,
  TextInput,
} from "react-admin";
import { CrudPermissions } from "../../auth/crud-permissions";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import {
  ConversionFilterListResource,
  ConversionResource,
  ConversionResourceNumberDataFields,
  ConversionSource,
  IsDefined,
  IsEmpty,
} from "@arrowsup/platform-dtos";
import { omit } from "lodash";

export const ConversionFilterList = (perms: CrudPermissions) => {
  return (
    <Resource
      name="conversion-filter"
      key={"conversion-filter"}
      options={{
        label: "Conversion Filters",
      }}
      list={ConversionFilterListList(perms)}
      create={perms.create ? ConversionFilterListCreate : undefined}
      edit={perms.update ? ConversionFilterListEdit : undefined}
      icon={FilterAltIcon}
    />
  );
};

export const ConversionFilterListList = (perms: CrudPermissions) => {
  return (
    <List>
      <Datagrid
        rowClick={perms.update ? "show" : false}
        bulkActionButtons={perms.delete ? <BulkDeleteButton /> : false}
      >
        <TextField source="id" label="Filter ID" />
        <TextField source="name" label="Filter Name" />
        <TextField source="notes" label="Notes" />
        <DateField source="createdAt" label="Created" />
        <DateField source="updatedAt" label="Updated" />
      </Datagrid>
    </List>
  );
};

export const FilterEditor = () => {
  return (
    <ArrayInput
      source="filters"
      label="Filters"
      helperText="Conversions that pass all filters are returned."
      fullWidth
    >
      <SimpleFormIterator inline>
        <SelectInput
          source="field"
          choices={[
            { id: "source", name: "Source" },
            { id: "eventTimeMs", name: "Event Time (Epoch MS)" },
            { id: "eventType", name: "Event Type" },
            { id: "eventKey", name: "Event Key" },
            { id: "user", name: "User" },
            { id: "firstName", name: "First Name" },
            { id: "lastName", name: "Last Name" },
            { id: "email", name: "Email" },
            { id: "phone", name: "Phone" },
            { id: "gender", name: "Gender" },
            { id: "dob", name: "Date of Birth" },
            { id: "streetAddress", name: "Street Address" },
            { id: "city", name: "City" },
            { id: "state", name: "State" },
            { id: "zip", name: "Zip" },
            { id: "country", name: "Country" },
            { id: "userAgent", name: "User Agent" },
            { id: "clientAddress", name: "Client Address" },
            { id: "url", name: "URL" },
            { id: "destinationPhone", name: "Destination Phone" },
            { id: "value", name: "Value" },
            { id: "attribute", name: "Attribute" },
            { id: "otherData", name: "Other (*)" },
          ]}
          fullWidth
        />
        <FormDataConsumer>
          {({ scopedFormData }) =>
            scopedFormData?.field ===
            ("otherData" satisfies keyof ConversionResource) ? (
              <TextInput source="subfield" fullWidth sx={{ mt: 1 }} />
            ) : null
          }
        </FormDataConsumer>
        <SelectInput
          source="comparison"
          choices={[
            { id: "eq", name: "Equals" },
            { id: "neq", name: "Not Equal" },
            { id: "gt", name: "Greater Than" },
            { id: "gte", name: "Greater Than or Equal" },
            { id: "lt", name: "Less Than" },
            { id: "lte", name: "Less Than or Equal" },
            { id: IsEmpty, name: "Is Unknown" },
            { id: IsDefined, name: "Is Known" },
          ]}
          fullWidth
        />
        <FormDataConsumer>
          {({ scopedFormData }) => {
            // We don't support entering array values in the UI, yet.  So if we
            // detect one, we disable the value field so it won't get borked.
            const isArray = Array.isArray(scopedFormData?.value);
            const isReadOnly = isArray;

            const fieldType: "source" | "text" | "number" =
              scopedFormData?.field ===
              ("source" satisfies keyof ConversionResource)
                ? "source"
                : Object.keys(ConversionResourceNumberDataFields).includes(
                    scopedFormData?.field as string
                  ) ||
                  // EventTimeMs is not a data field, but a special case.
                  scopedFormData?.field ===
                    ("eventTimeMs" satisfies keyof ConversionResource)
                ? "number"
                : "text";
            const noValueNeeded = [IsEmpty, IsDefined].includes(
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              scopedFormData?.comparison
            );
            if (noValueNeeded) {
              return null;
            }

            return fieldType === "text" ? (
              <TextInput
                source="value"
                fullWidth
                sx={{ mt: 1 }}
                readOnly={isReadOnly}
              />
            ) : fieldType === "source" ? (
              <SelectInput
                source="value"
                choices={Object.keys(ConversionSource.schema).map(
                  (_: string) => ({
                    id: _,
                    name: _,
                  })
                )}
                readOnly={isReadOnly}
              />
            ) : (
              <NumberInput
                source="value"
                fullWidth
                sx={{ mt: 1 }}
                readOnly={isReadOnly}
              />
            );
          }}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );
};

export const ConversionFilterListCreate = () => {
  return (
    <Create redirect="list">
      <SimpleForm>
        <TextInput source="name" label="Filter Name" fullWidth required />
        <TextInput source="notes" label="Notes" fullWidth />
        <FilterEditor />
      </SimpleForm>
    </Create>
  );
};

export const ConversionFilterListEdit = () => {
  return (
    <Edit
      redirect="list"
      transform={(d: ConversionFilterListResource) =>
        omit(d, ["createdAt", "updatedAt", "org"])
      }
    >
      <SimpleForm>
        <TextInput source="name" label="Filter Name" fullWidth required />
        <TextInput source="notes" label="Notes" fullWidth />
        <FilterEditor />
      </SimpleForm>
    </Edit>
  );
};
