import { AuResourceName } from "@arrowsup/platform-dtos";
import { useDataProvider } from "react-admin";
import { useQuery } from "@tanstack/react-query";

export const useConversionFilters = () => {
  const dataProvider = useDataProvider();
  return useQuery({
    queryKey: ["conversion-filter" satisfies AuResourceName],
    queryFn: ({ signal }) =>
      dataProvider.getList("conversion-filter" satisfies AuResourceName, {
        sort: {
          field: "name",
          order: "ASC",
        },
        pagination: {
          page: 1,
          perPage: 1000,
        },
        signal,
      }),
  });
};
