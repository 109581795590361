import React from "react";
import { CrudPermissions } from "../../auth/crud-permissions";
import ForwardIcon from "@mui/icons-material/Forward";
import {
  BulkDeleteButton,
  Create,
  Datagrid,
  Edit,
  List,
  NumberField,
  NumberInput,
  Resource,
  SimpleForm,
  TextField,
  TextInput,
} from "react-admin";

export const FunnelStageResource = (perms: CrudPermissions) => {
  return (
    <Resource
      name="funnel-stage"
      key="funnel-stage"
      options={{
        label: "Funnel Stages",
      }}
      icon={ForwardIcon}
      list={<FunnelStageList perms={perms} />}
      create={perms.create ? <FunnelStageCreate /> : undefined}
      edit={perms.update ? <FunnelStageEdit /> : undefined}
    />
  );
};

const FunnelStageList: React.FC<{ perms: CrudPermissions }> = ({ perms }) => {
  return (
    <List>
      <Datagrid
        rowClick={perms.update ? "show" : false}
        bulkActionButtons={perms.delete ? <BulkDeleteButton /> : false}
      >
        <TextField source="id" label="Stage ID" />
        <TextField source="name" label="Name" />
        <NumberField source="rank" label="Rank" />
      </Datagrid>
    </List>
  );
};

const FunnelStageCreate = () => {
  return (
    <Create redirect="list">
      <SimpleForm>
        <TextInput source="name" label="Name" fullWidth />
        <NumberInput source="rank" label="Rank" step={1} fullWidth />
      </SimpleForm>
    </Create>
  );
};

const FunnelStageEdit = () => {
  return (
    <Edit redirect="list">
      <SimpleForm>
        <TextInput source="name" label="Name" fullWidth />
        <NumberInput source="rank" label="Rank" step={1} fullWidth />
      </SimpleForm>
    </Edit>
  );
};
