import { Box } from "@mui/material";
import { AppBar, TitlePortal } from "react-admin";
import { ArrowsUpWhiteLogo } from "./ArrowsUpWhiteLogo";

export const AuAppBar = () => (
  <AppBar color="secondary" position="fixed">
    <TitlePortal />
    <Box
      sx={{
        display: { xs: "none", sm: "flex" },
        justifyContent: "right",
        flexGrow: 1,
      }}
    >
      <Box sx={{ width: 172, height: 30, pr: 1 }}>
        <ArrowsUpWhiteLogo />
      </Box>
    </Box>
  </AppBar>
);
