/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import {
  Edit,
  SimpleForm,
  TextInput,
  Create,
  Datagrid,
  EmailField,
  List,
  TextField,
  BulkDeleteButton,
  Resource,
} from "react-admin";
import { CrudPermissions } from "../auth/crud-permissions";
import { Group } from "@mui/icons-material";

/**
 * the resource that renders the UsersTable
 * @param usersPermissions Permissions, to see if user can update/delete the data
 * @returns the users resource
 */
export const UsersResource = (usersPermissions: CrudPermissions) => (
  <Resource
    name="users"
    list={UserList(usersPermissions.update, usersPermissions.delete)}
    edit={usersPermissions.update ? <UserEdit /> : undefined}
    create={usersPermissions.create ? <UserCreate /> : undefined}
    // necessary to get rid of react warning: https://react.dev/learn/rendering-lists#keeping-list-items-in-order-with-key
    key="users"
    icon={Group}
  />
);

/**
 * The ReactAdmin List element. Configures what the table looks like.
 * @param updateAllowed Is user allowed to update users?
 * @param deleteAllowed is user allowed to delete users?
 * @returns the List
 */
export const UserList = (updateAllowed: boolean, deleteAllowed: boolean) => (
  <List>
    <Datagrid
      rowClick={updateAllowed ? "show" : false}
      bulkActionButtons={deleteAllowed ? <BulkDeleteButton /> : false}
    >
      <TextField source="id" />
      <EmailField source="email" />
      <TextField source="name" />
    </Datagrid>
  </List>
);

/**
 * The Edit component.
 */
export const UserEdit = () => {
  return (
    <Edit redirect="list">
      <SimpleForm>
        <TextInput source="email" fullWidth />
        <TextInput source="name" fullWidth />
      </SimpleForm>
    </Edit>
  );
};

/**
 * The Create component
 */
export const UserCreate = () => {
  return (
    <Create redirect="list">
      <SimpleForm>
        <TextInput source="email" fullWidth />
        <TextInput source="name" fullWidth />
      </SimpleForm>
    </Create>
  );
};
