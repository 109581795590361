import {
  BooleanField,
  BooleanInput,
  BulkDeleteButton,
  ChipField,
  Create,
  Datagrid,
  DateField,
  Edit,
  List,
  Loading,
  Resource,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
} from "react-admin";
import { CrudPermissions } from "../../auth/crud-permissions";
import {
  ConversionFilterListResource,
  ConversionOutputStreamDestination,
  ConversionOutputStreamResource,
  StartFrom,
} from "@arrowsup/platform-dtos";
import { useConversionFilters } from "../../../logic/conversions/use-conversion-filter";
import OutputIcon from "@mui/icons-material/Output";
import { Box, Divider, Typography } from "@mui/material";
import { useWatch } from "react-hook-form";
import { omit } from "lodash";
import { ReactElement } from "react";

export const ConversionOutputStream = (perms: CrudPermissions) => {
  return (
    <Resource
      name="conversion-output-stream"
      key="conversion-output-stream"
      options={{
        label: "Output Streams",
      }}
      list={ConversionOutputStreamList(perms)}
      create={perms.create ? ConversionOutputStreamCreate() : undefined}
      edit={perms.update ? ConversionOutputStreamEdit() : undefined}
      icon={OutputIcon}
    />
  );
};

export const ConversionOutputStreamList = (perms: CrudPermissions) => {
  return (
    <List>
      <Datagrid
        rowClick={perms.update ? "show" : false}
        bulkActionButtons={perms.delete ? <BulkDeleteButton /> : false}
      >
        <TextField source="id" label="Stream ID" />
        <TextField source="description" label="Description" />
        <ChipField source="destination.name" label="Destination" />
        <TextField source="filter.name" label="Filter" />
        <ChipField source="startFrom" label="Start From" />
        <BooleanField source="enabled" />
        <DateField source="createdAt" label="Created" />
        <DateField source="updatedAt" label="Last Updated" />
      </Datagrid>
    </List>
  );
};

const FilterSelectInput: React.FC = () => {
  // Load saved filters.
  const { data, isPending, error } = useConversionFilters();

  if (isPending) {
    return <Loading />;
  }

  if (error) {
    throw new Error(`Error loading conversion filters: ${error.message}`);
  }

  return (
    <SelectInput
      source="filter.id"
      choices={(data.data as ConversionFilterListResource[]).map((filter) => ({
        id: filter.id,
        name: filter.name,
      }))}
      required
      fullWidth
    />
  );
};

const StartFromInput = () => {
  return (
    <SelectInput
      source="startFrom"
      choices={[
        { id: "beginning" satisfies StartFrom, name: "Beginning" },
        { id: "end" satisfies StartFrom, name: "End" },
      ]}
      defaultValue={"end" satisfies StartFrom}
      required
      fullWidth
    />
  );
};

const DestinationChooser: React.FC = () => {
  return (
    <SelectInput
      source="destination.name"
      choices={[
        {
          id: "email" satisfies ConversionOutputStreamDestination,
          name: "Email",
        },
        {
          id: "webhook" satisfies ConversionOutputStreamDestination,
          name: "Webhook",
        },
        {
          id: "meta-ads" satisfies ConversionOutputStreamDestination,
          name: "Meta Ads",
        },
        {
          id: "google-ads-conversion" satisfies ConversionOutputStreamDestination,
          name: "Google Ads Conversion",
        },
      ]}
      required
      fullWidth
    />
  );
};

const NoDestinationSet: React.FC = () => {
  return <Typography>No destination set.</Typography>;
};

const EmailDestinationConfig: React.FC = () => {
  return (
    <TextInput
      label="Destination Email Address"
      source="destination.to"
      fullWidth
    />
  );
};

const WebhookDestinationConfig: React.FC = () => {
  return (
    <>
      <TextInput
        label="Destination URL"
        source="destination.webhookUrl"
        fullWidth
      />
    </>
  );
};

const MetaAdsDestinationConfig: React.FC = () => {
  return (
    <>
      <TextInput
        label="Meta Access Token"
        source="destination.accessToken"
        fullWidth
      />
      <TextInput label="Meta Pixel ID" source="destination.pixelId" fullWidth />
      <TextInput
        label="Meta Event Name"
        source="destination.eventName"
        fullWidth
      />
      <TextInput
        label="Meta Account ID"
        source="destination.accountId"
        fullWidth
      />
    </>
  );
};

const GoogleAdsConversionDestinationConfig: React.FC = () => {
  return (
    <>
      <TextInput
        label="Google Ads Conversion Action ID"
        source="destination.conversionActionId"
        fullWidth
      />
      <BooleanInput label="Enhanced" source="destination.enhanced" fullWidth />
      <TextInput
        label="Google Ads Customer ID"
        source="destination.customerId"
        fullWidth
      />
    </>
  );
};

const DestinationConfig: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const destinationName: ConversionOutputStreamDestination | undefined =
    useWatch({ name: "destination.name" });

  const configMap: Record<ConversionOutputStreamDestination, ReactElement> = {
    email: <EmailDestinationConfig />,
    webhook: <WebhookDestinationConfig />,
    "meta-ads": <MetaAdsDestinationConfig />,
    "google-ads-conversion": <GoogleAdsConversionDestinationConfig />,
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Divider sx={{ pb: 2 }}>Destination Config</Divider>
      {configMap[destinationName ?? "email"] ?? <NoDestinationSet />}
    </Box>
  );
};

export const ConversionOutputStreamCreate = () => {
  return (
    <Create
      redirect="list"
      transform={(os: ConversionOutputStreamResource) => ({
        ...os,
      })}
    >
      <SimpleForm>
        <FilterSelectInput />
        <TextInput source="description" fullWidth />
        <StartFromInput />
        <BooleanInput fullWidth source="enabled" />
        <DestinationChooser />
        <DestinationConfig />
      </SimpleForm>
    </Create>
  );
};

export const ConversionOutputStreamEdit = () => {
  return (
    <Edit
      redirect="list"
      transform={(os: ConversionOutputStreamResource) => {
        return {
          ...omit(os, ["createdAt", "updatedAt"]),
          filter: { id: os.filter.id },
        };
      }}
    >
      <SimpleForm>
        <FilterSelectInput />
        <TextInput source="description" fullWidth />
        <StartFromInput />
        <BooleanInput fullWidth source="enabled" />
        <DestinationChooser />
        <DestinationConfig />
      </SimpleForm>
    </Edit>
  );
};
