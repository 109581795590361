import {
  BulkDeleteButton,
  Create,
  Datagrid,
  Edit,
  List,
  ReferenceInput,
  Resource,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
} from "react-admin";
import { CrudPermissions } from "../../auth/crud-permissions";
import React from "react";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { ConversionSource } from "@arrowsup/platform-dtos";

export const FunnelStageAssignmentResource = (perms: CrudPermissions) => {
  return (
    <Resource
      name="funnel-stage-assignment"
      key="funnel-stage-assignment"
      options={{
        label: "Funnel Stage Assignments",
      }}
      icon={AssignmentIcon}
      list={<FunnelStageAssignmentList perms={perms} />}
      create={perms.create ? <FunnelStageAssignmentCreate /> : undefined}
      edit={perms.update ? <FunnelStageAssignmentEdit /> : undefined}
    />
  );
};

const FunnelStageAssignmentList: React.FC<{ perms: CrudPermissions }> = ({
  perms,
}) => {
  return (
    <List>
      <Datagrid
        rowClick={perms.update ? "show" : false}
        bulkActionButtons={perms.delete ? <BulkDeleteButton /> : false}
      >
        <TextField source="id" label="Assignment ID" />
        <TextField source="stage.name" label="Stage Name" />
        <TextField source="source" label="Source" />
        <TextField source="eventType" label="Event Type" />
      </Datagrid>
    </List>
  );
};

const SourceInput = () => {
  return (
    <>
      <SelectInput
        source="source"
        label="Source"
        fullWidth
        choices={Object.keys(ConversionSource.schema).map((key) => ({
          id: key,
          name: key,
        }))}
        required
      />
    </>
  );
};

const StageInput = () => {
  return (
    <ReferenceInput source="stage.id" label="Stage" reference="funnel-stage">
      <SelectInput label="Stage" optionText="name" fullWidth />
    </ReferenceInput>
  );
};

const FunnelStageAssignmentCreate: React.FC = () => {
  return (
    <Create redirect="list">
      <SimpleForm>
        <StageInput />
        <SourceInput />
        <TextInput source="eventType" label="Event Type" fullWidth />
      </SimpleForm>
    </Create>
  );
};

const FunnelStageAssignmentEdit = () => {
  return (
    <Edit redirect="list">
      <SimpleForm>
        <StageInput />
        <SourceInput />
        <TextInput source="eventType" label="Event Type" fullWidth />
      </SimpleForm>
    </Edit>
  );
};
