import React, { Suspense, useContext } from "react";
import { Route } from "react-router-dom";
import { Admin, CustomRoutes, Loading } from "react-admin";
import { authConfigServiceFactory } from "../logic/auth/auth-config.service";
import { AuthRequired } from "../components/auth/auth-required";
import { useAuAuthProvider } from "../logic/auth/use-au-auth-provider";
import { useAuDataProvider } from "../logic/data-provider/use-au-data-provider";
import { UsersResource } from "../components/users/users";
import { getAllPermissions } from "../components/auth/crud-permissions";
import { AuPolicy } from "@arrowsup/platform-dtos";
import { IntegrationConfigsLayout } from "../components/integrations/integrations-config-layout";
import { OauthRedirect } from "../components/integrations/oauth/OauthRedirect";
import { BrowserRouter } from "react-router-dom";
import { useApi } from "../logic/api/use-api";
import { AuLayout } from "../components/menu/AuMenu";
import { RouteElement } from "../components/util/RouteElement";
import { useOauthServices } from "../components/integrations/oauth/use-oauth-services";
import { Box } from "@mui/material";
import { WebViewFilterResource } from "../components/web-view-filter/web-view-filter";
import { ApiContext } from "../logic/api/api-context";
import { auTheme } from "./theme";
import { GoodDataOrgConfigResource } from "../components/gooddata/gooddata-org-config";
import { GoodDataDashboardResource } from "../components/gooddata/gooddata-dashboard";
import { ErrorBoundary } from "react-error-boundary";
import { useAppConfigService } from "../logic/app-config/use-app-config.service";
import { Conversion } from "../components/conversions/conversion";
import { FatalError } from "../components/feedback/fatal-error";
import { ConversionFilterList } from "../components/conversions/conversion-filter-list/conversion-filter-list";
import { ConversionOutputStream } from "../components/conversions/conversion-output-stream/conversion-output-stream";
import { QueryClient } from "@tanstack/react-query";
import { PaidSourceResource } from "../components/conversions/tracking/paid-source";
import { TrackedCallResource } from "../components/conversions/tracking/tracked-call";
import { TrackedUrlResource } from "../components/conversions/tracking/tracked-url";
import { FunnelStageResource } from "../components/conversions/funnel/funnel-stage";
import { FunnelStageAssignmentResource } from "../components/conversions/funnel/funnel-stage-assignment";

// This causes annoying refreshes on the dashboard.
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

/**
 * The Core App. It looks up the permissions for the user based upon that users
 * permissions. Then shows/hides the appropriate resources.
 * @returns
 */
const CoreApp: React.FC = () => {
  const oauthServices = useOauthServices();
  const authProvider = useAuAuthProvider();
  const dataProvider = useAuDataProvider();
  const api = useContext(ApiContext).api;
  const acs = useAppConfigService(api);

  return (
    // need this special router tag to remove the hashtags from the urls,
    // because you can't input urls with hashtags into Zoho's allowed oauth urls
    // input box
    <BrowserRouter>
      <Admin // the actual ReactAdmin application
        theme={auTheme}
        dataProvider={dataProvider}
        authProvider={authProvider}
        queryClient={queryClient}
        layout={AuLayout} // needed to create custom layout in order to modify what was in the menu
      >
        {(permissions: AuPolicy[]) => {
          // magical framework parameter: https://marmelab.com/react-admin/Permissions.html#restricting-access-to-resources-or-views
          // a record that has permissions for all AuResourceNames
          const allPermissions = getAllPermissions(permissions);

          return (
            <>
              {allPermissions["dashboard"].read
                ? GoodDataDashboardResource(allPermissions["dashboard"])
                : null}
              {allPermissions["conversion"].read
                ? Conversion(allPermissions["conversion"])
                : null}
              {allPermissions["conversion-filter"].read
                ? ConversionFilterList(allPermissions["conversion-filter"])
                : null}
              {allPermissions["conversion-output-stream"].read
                ? ConversionOutputStream(
                    allPermissions["conversion-output-stream"]
                  )
                : null}
              {allPermissions["users"].read
                ? UsersResource(allPermissions["users"])
                : null}
              {allPermissions["web-view-filter"].read
                ? WebViewFilterResource(allPermissions["web-view-filter"])
                : null}
              {allPermissions["paid-source"].read
                ? PaidSourceResource(allPermissions["paid-source"])
                : null}
              {allPermissions["tracked-call"].read
                ? TrackedCallResource(allPermissions["tracked-call"])
                : null}
              {allPermissions["tracked-url"].read
                ? TrackedUrlResource(allPermissions["tracked-url"])
                : null}
              {allPermissions["funnel-stage"].read
                ? FunnelStageResource(allPermissions["funnel-stage"])
                : null}
              {allPermissions["funnel-stage-assignment"].read
                ? FunnelStageAssignmentResource(
                    allPermissions["funnel-stage-assignment"]
                  )
                : null}
              {allPermissions["gooddata-org-config"].read
                ? GoodDataOrgConfigResource(
                    allPermissions["gooddata-org-config"]
                  )
                : null}
              <CustomRoutes>
                <Route // this is the oauth integrations component
                  path="/integrations/*"
                  element={
                    <Box sx={{ marginTop: "2rem" }}>
                      <RouteElement title="Integrations">
                        <ErrorBoundary fallback={<FatalError />}>
                          <Suspense fallback={<Loading />}>
                            <IntegrationConfigsLayout
                              oauthServices={oauthServices}
                              acs={acs}
                            />
                          </Suspense>
                        </ErrorBoundary>
                      </RouteElement>
                    </Box>
                  }
                />
                <Route
                  path="/oauth-redirect/intuit"
                  element={
                    <RouteElement title="Intuit Oauth Redirect">
                      <OauthRedirect oauthService={oauthServices.intuit} />
                    </RouteElement>
                  }
                />
                <Route
                  path="/oauth-redirect/zoho"
                  element={
                    <RouteElement title="Zoho Oauth Redirect">
                      <OauthRedirect oauthService={oauthServices.zoho} />
                    </RouteElement>
                  }
                />
                <Route
                  path="/oauth-redirect/google"
                  element={
                    <RouteElement title="Google Oauth Redirect">
                      <OauthRedirect oauthService={oauthServices.google} />
                    </RouteElement>
                  }
                />
              </CustomRoutes>
            </>
          );
        }}
      </Admin>
    </BrowserRouter>
  );
};

/** Add ApiContext, which the app needs. */
const CoreAppBootstrap: React.FC = () => {
  const api = useApi();

  return (
    <ApiContext.Provider value={{ api }}>
      <CoreApp />
    </ApiContext.Provider>
  );
};

const App = () => {
  const authConfigService = authConfigServiceFactory();

  return (
    <AuthRequired authConfigService={authConfigService}>
      <CoreAppBootstrap />
    </AuthRequired>
  );
};

export default App;
